/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vuex from "vuex";

import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);


// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// API Calls
import "./http/requests";

// mock
// import './fake-db/index.js'

// Theme Configurations
import "../themeConfig.js";


// Firebase
import "@/firebase/firebaseConfig";


// Auth0 Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);


// ACL
import acl from "./acl/acl";


// Globally Registered Components
import "./globalComponents.js";


// Styles: SCSS
import "./assets/scss/main.scss";


// Tailwind
import "@/assets/css/main.css";


// Vue Router
import router from "./router";


// Vuex Store
import store from "./store/store";


// i18n
import i18n from "./i18n/i18n";


// Vuexy Admin Filters
import "./filters/filters";


// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);


// Tour
import VueTour from "vue-tour";
Vue.use(VueTour);
require("vue-tour/dist/vue-tour.css");


// VeeValidate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);


// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
	load: {
		// Add your API key here
		key: "AIzaSyCfCEXvQ9xlmaNMWGzEn_JerGG-HsYmfmA",
		libraries: "places" // This is required if you use the Auto complete plug-in
	}
});

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);


// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";


// Feather font icon
require("./assets/css/iconfont.css");


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";


const store_login = new Vuex.Store({
	state: {
		isLoggedIn: localStorage.getItem("authenticated") == "true"
	},
	mutations: {
		[LOGIN](state) {
			state.pending = true;
		},
		[LOGIN_SUCCESS](state) {
			state.isLoggedIn = true;
			state.pending = false;
		},
		[LOGOUT](state) {
			state.isLoggedIn = false;
		}
	},
	actions: {
		login({
			state,
			commit,
			rootState
		}, creds) {
			// after login success
			commit(LOGIN);
			return new Promise(resolve => {
				setTimeout(() => {
					if (localStorage.getItem("idToken")) {
						commit(LOGIN_SUCCESS);
						resolve();
					} else {
						router.push("/login");
					}
				}, 1000);
			});

		},
    
	},
	getters: {
		isLoggedIn: state => {
			return state.isLoggedIn;
		}
	}
});
router.beforeEach((to, from, next) => {
	var getloginDate = localStorage.getItem("login_date");
	var currentDate1 = new Date();
	var currentMonth = currentDate1.getMonth() + 1;
	if(currentMonth < 10) {
		currentMonth = "0" + currentMonth;
	} else {
		currentMonth = "" + currentMonth;
	}
	// const todate = `${currentDate1.getFullYear()}-${currentMonth }-${currentDate1.getDate()}`;
	const mydate=new Date(getloginDate);
	if(currentDate1>mydate)
	{
		localStorage.clear();
		localStorage.setItem("load_create",1);
	}

	//  if(todate == getloginDate){
	//  window.onbeforeunload = function (e) {
	//                  var storage = window.localStorage;
	//                  storage.clear()
	//                  localStorage.setItem('load_create',1)
	//              }
	//  }
	var orgName = "MHC";
	if(localStorage.getItem("org_name") !== null){
		orgName = localStorage.getItem("org_name");
	}
	//document.title = to.meta.title || "LEDex - "+ orgName +" Organization";
	document.title = to.meta.title || "MHC - Dashboard";

	if (to.matched.some(record => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (!localStorage.getItem("idToken")) {
			var nxt = { name: "page-login" /*, query: { redirect: from.path} */ };
			//query: { redirect: '/project-details/' + this.$router.params.id }
			next({ name: "page-login" });
		} else {
			next(); // go to wherever I'm going
		}
	} else {
		next(); // does not require auth, make sure to always call next()!
	}
});


Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	acl,
	render: h => h(App)
}).$mount("#app");

Vue.use(Vuex);