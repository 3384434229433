// axios
import axios from "axios";
import axiosRetry from 'axios-retry';

window.onunload = () => {
	// Clear the local storage
	window.MyStorage.clear();
};
//dev
const baseURL = 'https://api-mhc.8mile.sg';
const asset_url = 'https://mhc-assets.8mile.sg/';
const asset_s3_url = 'https://s3.us-east-2.amazonaws.com/mhc-assets.8mile.sg/';
const Pool_Id = 'us-east-2_vrhrBtBBL';
const client_id = '6541jo5fji0226nghvnlp84r1e';

//live urls
// const baseURL = 'https://liveapi-mhc.8mile.sg';
// const asset_url = 'https://livemhc-assets.8mile.sg/';
// const asset_s3_url = 'https://s3.ap-southeast-1.amazonaws.com/livemhc-assets.8mile.sg/';
// const Pool_Id = 'ap-southeast-1_57uvBgdZE';
// const client_id = '5h9f7o173nui9d0gka19ertv8s';


var QB_APPLICATION_ID = 102243;
var QB_AUTH_KEY = 'ak_auJ36YwFDUny-WS';
var QB_AUTH_SECRET = 'as_Lpu4snUS3zWvtuw';
var QB_ACCOUNT_KEY = 'tuzbQmzpGGukGbjzsXsx';
var QB_CONFIG = { debug: false };
var QB_LOGIN = 'Mhc';
var QB_PWD = 'Abc@123456';
var QB_CHAT_TMP_PWD = 'IsabelChat@2021';


const client = axios.create({
	client_id,
	Pool_Id,
	baseURL,
	asset_url,
	asset_s3_url,
	QB_APPLICATION_ID,
	QB_AUTH_KEY,
	QB_AUTH_SECRET,
	QB_ACCOUNT_KEY,
	QB_CONFIG,
	QB_LOGIN,
	QB_PWD,
	QB_CHAT_TMP_PWD
	// You can add your headers here
});
axiosRetry(client, { retries: 3 });
export default client
